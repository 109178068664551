<template>
  <div class="PurchasesHistoric">
    <q-dialog v-model="opened" class="q-pa-none">
      <q-card class="q-pa-none q-ma-none" v-if="order">
        <q-card-section class="text-center text-bold">
          <span class="text-h5">Fornecedor</span>
        </q-card-section>
        <q-card-section>
          <div class="row">
            <div class="col-6">
              <span class="text-bold">CNPJ: </span>
              <span>{{ order.cnpj }}</span>
            </div>
            <div class="col-6">
              <span class="text-bold">Loja: </span>
              <span>{{ order.supplierStore }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-bold">Fornecedor: </span>
              <span>{{ order.supplierName }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-bold">Endereço: </span>
              <span>{{ order.supplierAddress || "" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-bold">Município: </span>
              <span>{{
                `${order.supplierCity || ""} - ${order.supplierUf || ""}`
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="text-bold">Condição Pagto: </span>
              <span>{{ order.faymentForm }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="text-bold">Contato: </span>
              <span>{{ order.supplierContact || "" }}</span>
            </div>
            <div class="col-6">
              <span class="text-bold">Fone: </span>
              <span>{{ order.supplierPhone || "" }}</span>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pa-none q-ma-none">
          <DataTable
            title="Histórico de Compras com Fornecedor"
            :columns="columns"
            :data="order.purchasesHistoric"
            :gridMobile="false"
            dense
            hidePagination
          />
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Fechar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      opened: false,
      order: null,
      columns: [
        {
          field: "invoice",
          label: "Nota Fiscal",
          align: "center",
          sortable: true
        },
        {
          field: "purchaseDate",
          label: "Dt Compra",
          align: "center",
          type: "date",
          sortable: true
        },
        {
          field: "amount",
          label: "Valor",
          align: "right",
          type: "currency",
          sortable: true
        }
      ]
    };
  },
  methods: {
    open(order) {
      this.opened = true;
      this.order = order;
    }
  }
};
</script>
